import { render, staticRenderFns } from "./HomeHero.vue?vue&type=template&id=c9b29b3c"
import script from "./HomeHero.vue?vue&type=script&lang=js"
export * from "./HomeHero.vue?vue&type=script&lang=js"
import style0 from "./HomeHero.vue?vue&type=style&index=0&id=c9b29b3c&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Richtext: require('/vercel/path0/components/elements/Richtext.vue').default,Picture: require('/vercel/path0/components/elements/Picture.vue').default,Section: require('/vercel/path0/components/elements/Section.vue').default})
