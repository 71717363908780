
import imageUrlBuilder from '@sanity/image-url';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config';
import getImageDatasFromRef from '../../queries/getImageDatasFromRef';
import breakpoint from '../../mixins/breakpoint';

export default {
  mixins: [breakpoint],
  props: {
    aspectRatios: {
      type: Array,
      default() {
        return [
          { sm: 'default' },
          { md: 'default' },
          { lg: 'default' },
          { xl: 'default' },
          { '2xl': 'default' },
          { '3xl': 'default' }
        ];
      }
    },
    refImage: {
      type: String,
      default: () => {}
    },
    isLazyloaded: {
      type: Boolean,
      default: false
    },
    alt: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      picture: {
        asset: null,
        hotspot: null,
        crop: null,
        width: null,
        height: null
      },
      imageData: null,
      srcset: null,
      fallbackSrc: null,
      sizes: null,
      renderWidth: null,
      renderHeight: null,
      preloadHref: null
    };
  },

  head() {
    if (!this.picture.asset) {
      return;
    }

    return {
      link: [
        {
          rel: 'prefetch',
          as: 'image',
          href: this.preloadHref
        }
      ]
    };
  },

  watch: {},
  async mounted() {
    // eslint-disable-next-line
    if (!this.isValidRefImage() || !process.client) {
      return;
    }

    // load the reference document from Sanity
    const query = getImageDatasFromRef(this.refImage);
    const docReference = await this.$sanity.fetch(query);
    const { asset, crop, hotspot } = docReference;

    // we populate our global picture object
    this.picture.asset = { ...asset, crop, hotspot };
    this.picture.width = asset?.metadata?.dimensions?.width;
    this.picture.height = asset?.metadata?.dimensions?.height;

    this.imageData = this.generateImageData();
    this.srcset = this.generateSrcset();
    this.sizes = this.generateSizes();
  },

  methods: {
    onImageLoad() {
      const imageElement = this.$refs.image;
      if (imageElement) {
        this.renderWidth = imageElement.offsetWidth;
        this.renderHeight = imageElement.offsetHeight;
        this.fallbackSrc = this.generateSrc(this.renderWidth, this.renderHeight);
        this.preloadHref = this.preloadHref || this.fallbackSrc;
      }
    },
    getImageSizeByCurrentBreakpoint() {
      return this.imageData.find((img) => img.breakpoint === this.currentBreakpoint).picture;
    },
    isValidRefImage() {
      return (
        (!!this.refImage && this.refImage !== '' && typeof this.refImage === 'string') ||
        this.refImage instanceof String
      );
    },
    createBuilder() {
      return imageUrlBuilder(this.$config.api);
    },
    // This function is used to define the aspect ratio of the image according
    // to the parameters we defined when calling this component.
    getAspectRatioHeight(width, ratio) {
      if (ratio === 'square') {
        return width;
      }

      if (ratio === 'panorama') {
        return Math.round((width / 147) * 36.75);
      }

      if (ratio.includes(':')) {
        const dimension = ratio.split(':');
        return Math.round((width / parseFloat(dimension[0])) * parseFloat(dimension[1]));
      }

      // if default, then we return the natural height of the image
      return this.picture.asset?.metadata?.dimensions?.height;
    },
    generateSrcset() {
      const srcsetAttr = this.imageData
        .map((img) => {
          const isCurrentBreakpoint = img.breakpoint === this.currentBreakpoint;
          const imgUrl = this.createBuilder()
            .image(this.picture.asset)
            .width(img.picture.w)
            .height(img.picture.h)
            .auto('format')
            .dpr(1)
            .fit('crop')
            .url();

          // this value is used in the head() and will set a prefetch attr
          this.preloadHref = isCurrentBreakpoint && imgUrl;

          return `${imgUrl} ${img.size}w`;
        })
        .join(', ');

      return srcsetAttr;
    },
    generateSizes() {
      return this.imageData
        .map((img) => {
          return img.breakpoint === 'sm'
            ? `(min-width: 0w) and (max-width: ${img.size}w) ${img.size}w`
            : `(min-width: ${img.size}w) ${img.size}w`;
        })
        .join(', ');
    },
    generateSrc(width, height) {
      return this.createBuilder()
        .image(this.picture.asset)
        .width(width)
        .height(height)
        .auto('format')
        .dpr(1)
        .fit('crop')
        .url();
    },
    getAspectRatioByBreakpoint(breakpoint) {
      return this.aspectRatios.filter(
        (aspectRatio) => Object.keys(aspectRatio)[0] === breakpoint
      )[0]?.[breakpoint];
    },
    generateImageData() {
      const {
        theme: { screens }
      } = resolveConfig(tailwindConfig);

      const tailwindBreakpoints = Object.keys(screens);
      return tailwindBreakpoints.map((breakpoint) => {
        const breakpointWidthInPx = parseInt(screens[breakpoint].replace('px', ''));
        // check if the natural size of the image is greater than the breakpoint.
        // If so, then we should ask for an image as big as the breakpoint
        // If it is smaller than the breakpoint, we load the natural image
        const aspectRatio = this.getAspectRatioByBreakpoint(breakpoint);

        const srcset = {
          breakpoint,
          size: breakpointWidthInPx,
          picture: { w: 0, h: 0 },
          aspectRatio
        };

        const pictureNaturalWidth = this.picture.width;
        // the picture's natural size is bigger than the current breakpoint viewport
        // so we need to resize it based on the breakpoint size as max-width.
        if (pictureNaturalWidth > breakpointWidthInPx) {
          srcset.picture.w = breakpointWidthInPx;
          if (srcset.aspectRatio === 'default') {
            // we calculate the height of the image preserving the natural aspect ratio
            srcset.picture.h = Math.round(
              breakpointWidthInPx * (this.picture.height / pictureNaturalWidth)
            );
          } else {
            // we calculate height based on aspect ratio
            srcset.picture.h = this.getAspectRatioHeight(breakpointWidthInPx, srcset.aspectRatio);
          }
        } else {
          // if the image is smaller then the breakpoint screen size,
          // we want the natural sized image (not optimal for page rendering).
          srcset.picture.w = pictureNaturalWidth;
          srcset.picture.h = this.getAspectRatioHeight(pictureNaturalWidth, srcset.aspectRatio);
        }

        return srcset;
      });
    }
  }
};
